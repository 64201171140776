import { getAdminStart, getAdminSuccess, getAdminFailur, addAdminStart, addAdminSuccess, addAdminFailur, updateAdminStart, updateAdminSuccess, updateAdminFailure } from '../redux/adminRedux';
import { loginStart, loginSuccess, loginFailure, logout } from '../redux/authRedux';
import { addBrandFailur, addBrandStart, addBrandSuccess, getBrandFailur, getBrandStart, getBrandSuccess, updateBrandFailure, updateBrandStart, updateBrandSuccess } from '../redux/brandRedux';
import {getCategoryStart,getCategorySuccess,getCategoryFailur,addCategoryStart,addCategorySuccess,addCategoryFailur,updateCategoryStart,updateCategorySuccess,updateCategoryFailure} from '../redux/categoryRedux'
import axios from 'axios'
import { addProductFailur, addProductStart, addProductSuccess, getProductFailur, getProductStart, getProductSuccess, updateProductFailure, updateProductStart, updateProductSuccess } from '../redux/productRedux';
import { getShopStart, getShopSuccess, getShopFailur, addShopStart, addShopSuccess, addShopFailur, updateShopStart, updateShopSuccess, updateShopFailure} from '../redux/shopRedux';
import { getUserStart, getUserSuccess, getUserFailur, addUserStart, addUserSuccess, addUserFailur, updateUserStart, updateUserSuccess, updateUserFailure } from '../redux/userRedux';
import { siteSettingFailure, siteSettingStart, siteSettingSuccess } from '../redux/settingRedux';
import { vendorSettingStart, vendorSettingSuccess, vendorSettingFailure } from '../redux/vendorSettingRedux';
import { getPaymentMethodFailur, getPaymentMethodSuccess,getPaymentMethodStart, addPaymentMethodStart, addPaymentMethodSuccess, addPaymentMethodFailur, updatePaymentMethodStart, updatePaymentMethodSuccess, updatePaymentMethodFailure } from '../redux/paymentRedux';
import { addExpenseHeadFailur, addExpenseHeadStart, addExpenseHeadSuccess, getExpenseHeadFailur, getExpenseHeadStart, getExpenseHeadSuccess, updateExpenseHeadFailure, updateExpenseHeadStart, updateExpenseHeadSuccess } from '../redux/expenseHeadRedux';

// All Admin
export const getAllAdmin = async (dispatch, vartoken) =>{
    let res;
    dispatch(getAdminStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/admin/`, headers:{token: 'Bearer '+ vartoken}})
        dispatch(getAdminSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getAdminFailur())
        return res
    }
}

// Add Admin
export const addAdmin = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addAdminStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/admin/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addAdminSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addAdminFailur())
        return res
    }
}

// Delete Admin
export const deleteAdmin = async (dispatch, vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/admin/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Delete Admin
export const login = async (dispatch, data) =>{
    let res;
    dispatch(loginStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/admin/login`, data:data})
        dispatch(loginSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(loginFailure)
        return res
    }
}

// All Category
export const getAllCategory = async (dispatch) =>{
    let res;
    dispatch(getCategoryStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/category/`})
        dispatch(getCategorySuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getCategoryFailur())
        return res
    }
}

// Add Category
export const addCategory = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addCategoryStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/category/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addCategorySuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addCategoryFailur())
        return res
    }
}

// Update Category
export const updateCategory = async (dispatch, vartoken, data, id) => {
    dispatch(updateCategoryStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/category/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateCategory = res.data;
      dispatch(updateCategorySuccess({ id, updateCategory }));
      return res
    } catch (err) {
      dispatch(updateCategoryFailure());
      return res
    }
  };

// Delete Category
export const deleteCategory = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/category/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// All Brand
export const getAllBrand = async (dispatch) =>{
    let res;
    dispatch(getBrandStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/brand/`})
        dispatch(getBrandSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getBrandFailur())
        return res
    }
}

// Add Brand
export const addBrand = async (dispatch, vartoken, data) =>{
    dispatch(addBrandStart())
    try {
        let res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/brand/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addBrandSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addBrandFailur())
        return error
    }
}

// Update Brand
export const updateBrand = async (dispatch, vartoken, data, id) => {
    dispatch(updateBrandStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/brand/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateBrand = res.data;
      dispatch(updateBrandSuccess({ id, updateBrand }));
      return res
    } catch (err) {
      dispatch(updateBrandFailure());
      return res
    }
  };

// Delete Brand
export const deleteBrand = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/brand/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// All Products
export const getAllProduct = async (dispatch) =>{
    let res;
    dispatch(getProductStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/product/`})
        dispatch(getProductSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getProductFailur())
        return res
    }
}
// get requested Products
export const getRequestedProducts = async (vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/store?status=false`, headers:{token : 'Bearer ' + vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Product
export const addProduct = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addProductStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/product/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addProductSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addProductFailur())
        return res
    }
}

// Update Product
export const updateProduct = async (dispatch, vartoken, data, id) => {
    dispatch(updateProductStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/product/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateProduct = res.data;
      dispatch(updateProductSuccess({ id, updateProduct }));
      return res
    } catch (err) {
      dispatch(updateProductFailure());
      return res
    }
  };

// Update Product
export const updateProductToStore = async (vartoken, data, id, prodid) => {
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/store/${id}/${prodid}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      return res
    } catch (err) {
      return res
    }
  };

// Delete Product
export const deleteProduct = async ( vartoken, uid, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/product/${uid}/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Delete Product
export const deleteProductToStore = async ( vartoken, id) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/store/${id}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Media
export const addMedia = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// delete Media
export const deleteMedia = async (vartoken, id) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/media/${id}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

export const deleteMultiMedia = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/multidelete`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

export const deleteMediaFromStorage = async (vartoken, imagename) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/media/delete/${imagename}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

export const deleteMultiMediaFromStorage = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/delete/multi`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

export const deleteBrandMediaFromStorage = async (vartoken, brandId, imagename) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/media/delete/${brandId}/${imagename}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}
// Upload Media
export const uploadMedia = async (vartoken, data, id) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/upload/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

// Upload Media
export const uploaBrandPhotos = async (vartoken, data, id, brandId) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/media/upload/brand/${id}/${brandId}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return res
    }
}

// All Media
export const loadMedia = async () => {
    let res;
    try {
      res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/media/`})
      return res
    } catch (err) {
      console.log(err);
      return res
    }
  };


  // All Shops
export const getAllShops = async (dispatch) =>{
    let res;
    dispatch(getShopStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/shop/`})
        dispatch(getShopSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getShopFailur())
        return res
    }
}

// Add shop
export const addShop = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addShopStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/shop/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addShopSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addShopFailur())
        return res
    }
}

// Update Shop
export const UpdateShop = async (dispatch, vartoken, data, id) => {
    dispatch(updateShopStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/shop/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateShop = res.data;
      dispatch(updateShopSuccess({ id, updateShop }));
      return res
    } catch (err) {
      dispatch(updateShopFailure());
      return res
    }
  };

// Delete Shop
export const deleteShop = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/shop/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

  // All Users
  export const getAllUsers = async (dispatch, vartoken) =>{
    let res;
    dispatch(getUserStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/user/`, headers:{token: 'Bearer '+ vartoken}})
        dispatch(getUserSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getUserFailur())
        return res
    }
}

// Update User
export const UpdateUser = async (dispatch, vartoken, data, id) => {
    dispatch(updateUserStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/user/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateUser = res.data;
      dispatch(updateUserSuccess({ id, updateUser }));
      return res
    } catch (err) {
      dispatch(updateUserFailure());
      return res
    }
  };

// Delete User
export const deleteUser = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/user/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

export const siteSetting = async (dispatch) => {
    dispatch(siteSettingStart())
    let res;
    try {
      res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/sitesetting`})
      dispatch(siteSettingSuccess(res.data[0]));
      return res
    } catch (err) {
      dispatch(siteSettingFailure());
      return res
    }
};
export const updateSiteSetting = async (dispatch, data, vartoken) => {
    dispatch(siteSettingStart())
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/sitesetting/editsite?id=${data._id}`, data:data, headers:{token: 'Bearer '+ vartoken}})
      dispatch(siteSettingSuccess(res.data));
      return res
    } catch (err) {
      dispatch(siteSettingFailure());
      return res
    }
};
export const vendorsiteSetting = async (dispatch) => {
    dispatch(vendorSettingStart())
    let res;
    try {
      res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/vendorsetting`})
      dispatch(vendorSettingSuccess(res.data[0]));
      return res
    } catch (err) {
      dispatch(vendorSettingFailure());
      return res
    }
};
export const updatevendorSiteSetting = async (dispatch, data, vartoken) => {
    dispatch(vendorSettingStart())
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/vendorsetting/editvendor?id=${data._id}`, data:data, headers:{token: 'Bearer '+ vartoken}})
      dispatch(vendorSettingSuccess(res.data));
      return res
    } catch (err) {
      dispatch(vendorSettingFailure());
      return res
    }
};


// All Banners
export const getAllBanners = async (vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/slider/`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Delete banner
export const deleteBanner = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/slider/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Banner
export const addBanner = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/slider/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// All voucher
export const getAllVoucher = async (vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/voucher/`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}
// Delete voucher
export const deletevoucher = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/voucher/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

// Add Voucher
export const addVoucher = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/voucher/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return error
    }
}

// All Offer
export const getAllOffer = async (type) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/offer?type=${type}`})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

// Delete Offer
export const deleteOffer = async ( vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/offer/${data}` ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

// Add Offer
export const addOffer = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/offer/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return error
    }
}
// Fetch Offer
export const fetchOrder = async (vartoken, id, from, to, storeid) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/order/getorder/${id}?from=${from}&to=${to}&storeid=${storeid}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return error
    }
}

// All paymentmethod
export const getAllPaymentMethod = async (vartoken, dispatch) =>{
    let res;
    dispatch(getPaymentMethodStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/paymentmethod`, headers:{token: 'Bearer '+ vartoken}})
        dispatch(getPaymentMethodSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getPaymentMethodFailur())
        return error
    }
}

// Add paymentmethod
export const addPaymentMethod = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addPaymentMethodStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/paymentmethod/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addPaymentMethodSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addPaymentMethodFailur())
        return res
    }
}

// Update PaymentMethod
export const updatePaymentMethod = async (dispatch, vartoken, data, id) => {
    dispatch(updatePaymentMethodStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/paymentmethod/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateBrand = res.data;
      dispatch(updatePaymentMethodSuccess({ id, updateBrand }));
      return res
    } catch (err) {
      dispatch(updatePaymentMethodFailure());
      return res
    }
  };

  // All Expense head
export const getAllExpenseHead = async (vartoken, dispatch) =>{
    let res;
    dispatch(getExpenseHeadStart())
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/expensehead`, headers:{token: 'Bearer '+ vartoken}})
        dispatch(getExpenseHeadSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(getExpenseHeadFailur())
        return error
    }
}

// Add Expense head
export const addExpenseHead = async (dispatch, vartoken, data) =>{
    let res;
    dispatch(addExpenseHeadStart())
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/expensehead/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        dispatch(addExpenseHeadSuccess(res.data))
        return res
    } catch (error) {
        console.log(error);
        dispatch(addExpenseHeadFailur())
        return res
    }
}

// Update Expense head
export const updateExpenseHead = async (dispatch, vartoken, data, id) => {
    dispatch(updateExpenseHeadStart());
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/expensehead/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      const updateExpenseHead = res.data;
      dispatch(updateExpenseHeadSuccess({ id, updateExpenseHead }));
      return res
    } catch (err) {
      dispatch(updateExpenseHeadFailure());
      return res
    }
  };

  // All Expenses
 export const getAllExpenses = async (vartoken,fromdate,todate) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/expense?fromdate=${fromdate}&todate=${todate}`,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Expenses
export const addExpenses = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/expense/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Update Expenses
export const UpdateExpenses = async (vartoken, data, id) => {
    let res;
    try {
      res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/expense/${id}`, data:data ,headers:{token: 'Bearer '+ vartoken}})
      return res
    } catch (err) {
        console.log(err);
      return res
    }
  };

  //allAds
export const getAllAds = async (status, id, vartoken) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/ads/${id}?status=${status}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add ads
export const createAd = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/ads/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        return error
    }
}

// get bid by ad id
export const getbidbyadid = async (id, vartoken, adid) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/biding/${id}/${adid}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// get updatead by ad id
export const updateAdByAdid = async (id, vartoken, adid, data) =>{
    let res;
    try {
        res = await axios({method:'put',url:`${process.env.REACT_APP_BASE_URL}/ads/${id}/${adid}`, data:data, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// deleteAd
export const deleteAdById = async (vartoken, adid) =>{
    let res;
    try {
        res = await axios({method:'delete',url:`${process.env.REACT_APP_BASE_URL}/ads/${adid}`, headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

// Add Section
export const addSection = async (vartoken, data) =>{
    let res;
    try {
        res = await axios({method:'post',url:`${process.env.REACT_APP_BASE_URL}/section/`, data:data ,headers:{token: 'Bearer '+ vartoken}})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}

  // get Products by store
  export const getProductsByStore = async (id) =>{
    let res;
    try {
        res = await axios({method:'get',url:`${process.env.REACT_APP_BASE_URL}/store/yourstore/${id}?status=true`})
        return res
    } catch (error) {
        console.log(error);
        return res
    }
}