import React,{useState}from 'react'
import { Container, Row, Col, FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import slugify from 'react-slugify';
import { addBanner, addCategory } from '../../util/apis';
import { updateNotificationSuccess } from '../../redux/notificationRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Media from '../Media/Media';

export function AddSlider(props) {
    const [slug, setslug] = useState("")
    const [imgUrl, setimgUrl] = useState(null)
    const dispatch = useDispatch()
    const [inputs, setinputs] = useState({})
    const [error, seterror] = useState("")
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const category = useSelector((state) => state.category.categories)
    let notification = useSelector((state)=> state.notification.notification)

    const uploadImg = (childData) =>{
        setModal(!modal)
        setimgUrl(childData)
    }
    const handleChange = (e) =>{
        setinputs(prev=>{
            return{...prev, [e.target.name]:e.target.value};
        });
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        let slider = {...inputs, imgurl:imgUrl}
        addBanner(adminUser?.accessToken, slider).then((res)=>{
            if (res.data) {
                dispatch(updateNotificationSuccess({sms:"Banner Added Success",status:true}))
                navigate('/sliderlist')
            } else {
                dispatch(updateNotificationSuccess({sms:"Banner Added Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                console.log("error");
            }
        })
    }

    return (
        <>
        {modal? (
            <div className='modal-outer'>
                <FontAwesomeIcon icon={faTimes} onClick={()=>setModal(!modal)} />
                <Media uploadImg={uploadImg} />
            </div>
        ) : ""}
        <Container>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            <div className='custom-form'>
                <div className='custom-card form-heading'>
                    <h4>Create Slider</h4>
                </div>
                <form className='custom-card' onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={9} md={8}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Slider Title</label>
                                        <FormControl type='text' name='slidertitle' onChange={handleChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Button Text</label>
                                        <FormControl type='text' name='btntext' onChange={handleChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Button Link</label>
                                        <FormControl type='text' name='btnlink' onChange={handleChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Alternative Text</label>
                                        <FormControl type='text' name='alttext' onChange={handleChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Select slider Category</label>
                                        <select className='form-control' name='sliderCategoryId' onChange={handleChange} required>
                                            <option>Select For</option>
                                            {category.filter(cate=>cate.type === 'graphics').map((cate)=>(
                                                <option value={cate?._id}>{cate?.nameInEnglish}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} md={4}>
                            {imgUrl !== null? <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/${imgUrl}`} onClick={uploadImg} className='w-100 mb-3' /> : <div className="form-group upload-img" onClick={uploadImg}>
                                <label>
                                <svg width="72" height="57" viewBox="0 0 72 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M68.0057 10.3334V7.00004H60.8858V10.3334H56.3326L52.7727 0.333374H19.1595L15.5995 10.3334H0.366455V57H71.5657V10.3334H68.0057ZM64.4458 50.3334H7.48638V17H20.733L24.293 7.00004H47.6392L51.1992 17H64.4458V50.3334Z" fill="#E1E1E1"/>
                                <path d="M35.9661 17C32.7977 17 29.7004 17.8797 27.066 19.528C24.4315 21.1762 22.3782 23.5189 21.1657 26.2597C19.9532 29.0006 19.636 32.0166 20.2541 34.9264C20.8722 37.8361 22.398 40.5088 24.6384 42.6066C26.8788 44.7044 29.7333 46.133 32.8408 46.7118C35.9484 47.2906 39.1694 46.9935 42.0966 45.8582C45.0239 44.7229 47.5258 42.8003 49.2861 40.3336C51.0464 37.8668 51.986 34.9667 51.986 32C51.9812 28.0231 50.2919 24.2104 47.2887 21.3983C44.2854 18.5862 40.2134 17.0044 35.9661 17ZM35.9661 40.3333C34.2059 40.3333 32.4852 39.8446 31.0216 38.9289C29.558 38.0132 28.4173 36.7117 27.7437 35.189C27.0701 33.6663 26.8938 31.9908 27.2372 30.3742C27.5806 28.7577 28.4283 27.2729 29.6729 26.1074C30.9176 24.942 32.5034 24.1483 34.2298 23.8268C35.9563 23.5052 37.7457 23.6703 39.372 24.301C40.9982 24.9317 42.3882 25.9998 43.3661 27.3702C44.3441 28.7407 44.866 30.3518 44.866 32C44.866 34.2101 43.9284 36.3298 42.2593 37.8926C40.5903 39.4554 38.3265 40.3333 35.9661 40.3333Z" fill="#E1E1E1"/>
                                </svg>
                                Click here to upload Images
                                </label>
                                <div className="preview-img">
                                <img className="w-100"/>
                                </div>
                            </div>}
                            <FormGroup className='form-group'>
                                <select className='form-control' name='status' onChange={handleChange} required>
                                    <option>Select Status</option>
                                    <option value="0">Active</option>
                                    <option value="1">Inactive</option>
                                </select>
                            </FormGroup>
                            <Button variant='success' className='w-100' type='submit'>Submit</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        </Container>
        </>
    )
}
