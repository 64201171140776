import React from 'react'
import AdminList from '../components/Admins/AdminList'

export function Admin() {
  return (
    <>
        <AdminList/>
    </>
  )
}
