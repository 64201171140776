import React from 'react'
import { faFolder, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState,useEffect } from 'react'
import SingleBrandPhotos from './SingleBrandPhotos'
import { addMedia,uploaBrandPhotos,loadMedia, deleteMedia, deleteBrandMediaFromStorage } from '../../util/apis'
import { useSelector } from 'react-redux'

export default function BrandMediaFolder({upImage, folder, medias, loadtotalMedia}) {
    const [isAlbumOpen, setisAlbumOpen] = useState(false)
    const [Urls, setUrls] = useState(false)
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    let files = [];
    let library = {};
    const [uploader, setuploader] = useState(false);
    const handleOpenAlbum = (e) =>{
        e.preventDefault();
        setisAlbumOpen(true);
    }

    const handleUpload = (e) => {
        setuploader(true)
        files = e.target.files;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
          if (files[i].type !== 'image/jpeg' && files[i].type !== 'image/png' && files[i].type !== 'image/jpg' && files[i].type !== 'image/pdf') {
            console.log("File is not in expected format");
          }else{
            console.log("File is in expected format");
            library = { fileName: files[i].name, fileType: files[i].type, brandId:folder?._id };
            addMedia(adminUser?.accessToken, library)
            .then((res)=>{
              console.log("Media added to database successfully");
            })
            .catch((e) => {
              console.log("error", e);
            });
            console.log("File append");
            data.append("file", files[i]);
          }
        }
    
        uploaBrandPhotos(adminUser?.accessToken, data, adminUser?._id, folder?._id)
        .then(() => {
            console.log("Media uploaded to server successfully");
        })
        .catch((e) => {
            console.log("error", e);
        })
        .then(() => {
            loadtotalMedia()
            setuploader(false)
            // setmediaLibrary(true)
            // setupImg(false)
        });
      }

    const handleDelete = (imagename) => {
        deleteMedia(adminUser?.accessToken, imagename._id)
        .then((res) => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        });

        deleteBrandMediaFromStorage(adminUser?.accessToken, folder?._id, imagename.fileName)
        .then((res) => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        })
        .then(() => {
          loadtotalMedia()
        });
    };

  return (
    <div className='single-img folder-album'>
        <div className='folder-top' onClick={handleOpenAlbum}>
            <FontAwesomeIcon icon={faFolder} />
            <p>{folder?.brandName}</p>
        </div>
        {isAlbumOpen ? (
            <div className='folder-bottom'>
                <FontAwesomeIcon className='close-folder' icon={faTimes} onClick={()=>setisAlbumOpen(false)} />
                <div className='brand-image-list'>
                    <div class="single-upload-img" style={{width:"150px",height:"150px",margin:"5px"}}><form><h1>Drop Files Here To Upload</h1><p>Or</p><input type="file" onChange={handleUpload} multiple/><a>Select Files</a></form></div>
                    {medias?.filter(media => media.brandId === folder?._id).map((media)=>(
                        <SingleBrandPhotos upImage={upImage} image={media} handleDelete={handleDelete} key={media?._id} />
                    ))}
                </div>
            </div>
        ) : ""}
    </div>
  )
}
