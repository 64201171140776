import { faAngleDown, faBell, faEnvelope, faRightFromBracket, faSearch, faUserAlt, faBoxOpen, faCopyright, faDashboard, faList, faBars, faShop, faUsers, faPhotoFilm, faGears, faSliders, faDollar, faDollarSign, faTags, faPercentage, faBoxes, faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useEffect, useState} from 'react'
import {Button, Col, Container, FormControl, FormGroup, Row} from 'react-bootstrap'
import { Link, useLocation, NavLink } from 'react-router-dom'
import "./navbar.css"
import profileimg from '../../imgs/profile.png'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/authRedux'
import logo from '../../imgs/logo.png'

export function Navbar() {
    const dispatch = useDispatch()
    const [isDropdownOpen, setisDropdownOpen] = useState(false)
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const location = useLocation()

    useEffect(() => {
        setisDropdownOpen(false)
    }, [location.pathname])

    return (
        <>
            <header>
                <Container className='h-100'>
                    <Row className='h-100'>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <div className='logo' style={{height:'100%'}}>
                                <Link to="/dashboard" style={{display:'flex',height:'100%',alignItems:'center'}}>
                                    <img src={logo} alt="logo" style={{height:'40px'}}/>
                                </Link>
                            </div>
                            {/* <div className='searchbar'>
                                <FormGroup className='form-group'>
                                    <FormControl type='text' placeholder='Search Here' />
                                    <Button><FontAwesomeIcon icon={faSearch} /></Button>
                                </FormGroup>
                            </div> */}
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <div className='profile-info'>
                                <div className='widgets'>
                                    <Link to="/">
                                        {/* <span>0</span> */}
                                        <FontAwesomeIcon icon={faBell} />
                                    </Link>
                                </div>
                                <div className='widgets'>
                                    <Link to="/">
                                        {/* <span>0</span> */}
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </Link>
                                </div>
                                <div className='widgets'>
                                    <div className='profile-thumb'>
                                        <Link to="/">
                                            <div className='profile-thumb-img'>
                                                <img src={profileimg} className='w-100' alt="" />
                                            </div>
                                        </Link>
                                        <FontAwesomeIcon icon={faAngleDown} onClick={()=>setisDropdownOpen(!isDropdownOpen)} />
                                        <ul className='dropdown-list' style={isDropdownOpen? {opacity:"1",visibility:'visible'} : {}}>
                                            <li>
                                                <div className='profile-thumb-info'>
                                                    <h5>{adminUser.fullname}</h5>
                                                    <p>{adminUser.role}</p>
                                                </div>
                                            </li>
                                            <li><Link to="/profile"><FontAwesomeIcon icon={faUserAlt} />Profile</Link></li>
                                            <li><a onClick={()=>dispatch(logout())}><FontAwesomeIcon icon={faRightFromBracket} />Logout</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className='navbar'>
                <Container>
                    <ul className='navbar-bottom'>
                        {adminUser?.role === 'superadmin'? (
                            <>
                                <li className='navbar-item'>
                                    <NavLink to="/dashboard" activeStyle>
                                        <FontAwesomeIcon icon={faDashboard} />
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/category" activeStyle>
                                        <FontAwesomeIcon icon={faList} />
                                        Category
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/brand" activeStyle>
                                        <FontAwesomeIcon icon={faCopyright} />
                                        Brand
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/products" activeStyle>
                                        <FontAwesomeIcon icon={faBoxOpen} />
                                        Products
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedproducts">
                                            Requested Products
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/shops" activeStyle>
                                        <FontAwesomeIcon icon={faShop} />
                                        Uddokta
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedshop">
                                            Requested Uddokta
                                        </NavLink>
                                    </div>
                                </li>
                                {/* <li className='navbar-item'>
                                    <NavLink to="/orders" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Orders
                                    </NavLink>
                                </li> */}
                                <li className='navbar-item'>
                                    <NavLink to="/admins" activeStyle>
                                        <FontAwesomeIcon icon={faUsers} />
                                        Admins
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/users" activeStyle>
                                        <FontAwesomeIcon icon={faUsers} />
                                        Users
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/medias" activeStyle>
                                        <FontAwesomeIcon icon={faPhotoFilm} />
                                        Media
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/sliderlist" activeStyle>
                                        <FontAwesomeIcon icon={faSliders} />
                                        Sliders
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/voucherlist" activeStyle>
                                        <FontAwesomeIcon icon={faTags} />
                                        Voucher
                                    </NavLink>
                                    {/* <div className='submenu'>
                                        <NavLink to="/settings">
                                            User panel Settings
                                        </NavLink>
                                        <NavLink to="/vendorsettings">
                                            Uddokta panel Settings
                                        </NavLink>
                                    </div> */}
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/offer" activeStyle>
                                        <FontAwesomeIcon icon={faPercentage} />
                                        Offer
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/orders" activeStyle>
                                        <FontAwesomeIcon icon={faBoxes} />
                                        Orders
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/settings" activeStyle>
                                        <FontAwesomeIcon icon={faGears} />
                                        Settings
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/settings">
                                            User panel Settings
                                        </NavLink>
                                        <NavLink to="/vendorsettings">
                                            Uddokta panel Settings
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/paymentmethod" activeStyle>
                                        <FontAwesomeIcon icon={faDollar} />
                                        Payment Method
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/expense" activeStyle>
                                        <FontAwesomeIcon icon={faDollarSign} />
                                        Expense
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/expense_head">
                                            Expense Head
                                        </NavLink>
                                        <NavLink to="/expense">
                                            Expense List
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/ads" activeStyle>
                                        <FontAwesomeIcon icon={faPhotoFilm} />
                                        Ads
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/inbiding">
                                            Inbiding
                                        </NavLink>
                                        <NavLink to="/expense">
                                            Previous Ads
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/section" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Sections
                                    </NavLink>
                                </li>
                            </>
                        ) : adminUser?.role === 'admin'? (
                            <>
                                <li className='navbar-item'>
                                    <NavLink to="/dashboard" activeStyle>
                                        <FontAwesomeIcon icon={faDashboard} />
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/category" activeStyle>
                                        <FontAwesomeIcon icon={faList} />
                                        Category
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/brand" activeStyle>
                                        <FontAwesomeIcon icon={faCopyright} />
                                        Brand
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/products" activeStyle>
                                        <FontAwesomeIcon icon={faBoxOpen} />
                                        Products
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedproducts">
                                            Requested Products
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/shops" activeStyle>
                                        <FontAwesomeIcon icon={faShop} />
                                        Uddokta
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedshop">
                                            Requested Uddokta
                                        </NavLink>
                                    </div>
                                </li>
                                {/* <li className='navbar-item'>
                                    <NavLink to="/orders" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Orders
                                    </NavLink>
                                </li> */}
                                <li className='navbar-item'>
                                    <NavLink to="/medias" activeStyle>
                                        <FontAwesomeIcon icon={faPhotoFilm} />
                                        Media
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/sliderlist" activeStyle>
                                        <FontAwesomeIcon icon={faSliders} />
                                        Sliders
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/settings" activeStyle>
                                        <FontAwesomeIcon icon={faGears} />
                                        Settings
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/settings">
                                            User panel Settings
                                        </NavLink>
                                        <NavLink to="/vendorsettings">
                                            Uddokta panel Settings
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/ads" activeStyle>
                                        <FontAwesomeIcon icon={faPhotoFilm} />
                                        Ads
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/inbiding">
                                            Inbiding
                                        </NavLink>
                                        <NavLink to="/expense">
                                            Previous Ads
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/section" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Sections
                                    </NavLink>
                                </li>
                            </>
                        ) : adminUser?.role === 'manager'? (
                            <>
                                <li className='navbar-item'>
                                    <NavLink to="/dashboard" activeStyle>
                                        <FontAwesomeIcon icon={faDashboard} />
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/category" activeStyle>
                                        <FontAwesomeIcon icon={faList} />
                                        Category
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/brand" activeStyle>
                                        <FontAwesomeIcon icon={faCopyright} />
                                        Brand
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/products" activeStyle>
                                        <FontAwesomeIcon icon={faBoxOpen} />
                                        Products
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedproducts">
                                            Requested Products
                                        </NavLink>
                                    </div>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/shops" activeStyle>
                                        <FontAwesomeIcon icon={faShop} />
                                        Uddokta
                                    </NavLink>
                                    <div className='submenu'>
                                        <NavLink to="/requestedshop">
                                            Requested Uddokta
                                        </NavLink>
                                    </div>
                                </li>
                                {/* <li className='navbar-item'>
                                    <NavLink to="/orders" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Orders
                                    </NavLink>
                                </li> */}
                                <li className='navbar-item'>
                                    <NavLink to="/medias" activeStyle>
                                        <FontAwesomeIcon icon={faPhotoFilm} />
                                        Media
                                    </NavLink>
                                </li>
                                <li className='navbar-item'>
                                    <NavLink to="/section" activeStyle>
                                        <FontAwesomeIcon icon={faBox} />
                                        Sections
                                    </NavLink>
                                </li>
                            </>
                        ) : ""}
                    </ul>
                </Container>
            </div>
        </>
    )
}
