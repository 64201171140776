import { createSlice } from "@reduxjs/toolkit";

export const paymentMethodSlice = createSlice({
  name: "payment",
  initialState: {
    methods: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getPaymentMethodStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getPaymentMethodSuccess: (state, action) => {
      state.isFetching = false;
      state.methods = action.payload;
    },
    getPaymentMethodFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addPaymentMethodStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addPaymentMethodSuccess: (state, action) => {
      state.isFetching = false;
      state.methods.unshift(action.payload);
    },
    addPaymentMethodFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updatePaymentMethodStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updatePaymentMethodSuccess: (state, action) => {
      state.isFetching = false;
      state.methods[
        state.methods.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updatePaymentMethod;
    },
    updatePaymentMethodFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deletePaymentMethodStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deletePaymentMethodSuccess: (state, action) => {
      state.isFetching = false;
      state.methods.splice(
        state.methods.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deletePaymentMethodFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getPaymentMethodStart,
  getPaymentMethodSuccess,
  getPaymentMethodFailur,
  addPaymentMethodStart,
  addPaymentMethodSuccess,
  addPaymentMethodFailur,
  updatePaymentMethodStart,
  updatePaymentMethodSuccess,
  updatePaymentMethodFailure,
  deletePaymentMethodStart,
  deletePaymentMethodSuccess,
  deletePaymentMethodFailure
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
