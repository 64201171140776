import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree, faBoxes, faHotel, faShop, faShirt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Shops() {
  const shop = useSelector((state)=>state.shop.shops)
  let food = [...shop];
  food = food.filter(item=>item.businesstype === 'food');
  let grocery = [...shop];
  grocery = grocery.filter(item=>item.businesstype === 'grocery');
  let fashions = [...shop];
  fashions = fashions.filter(item=>item.businesstype === 'fashion');
  return (
    <Container>
        <div className='dashboard-inner'>
                <h5 className='mt-3 mb-3'>Uddokta</h5>
                <Row>
                    <Col lg={3}>
                        <Link to="/shoplist" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Shops</p>
                                <h6>{shop?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/restaurent" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Restaurent</p>
                                <h6>{food?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faHotel} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/supermarket" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Grocery</p>
                                <h6>{grocery?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faShop} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/fashion" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Lifestyle</p>
                                <h6>{fashions?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faShirt} />
                            </div>
                        </Link>
                    </Col>
                </Row>
            </div>
    </Container>
  )
}
