import { createSlice } from "@reduxjs/toolkit";

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    shops: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getShopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getShopSuccess: (state, action) => {
      state.isFetching = false;
      state.shops = action.payload;
    },
    getShopFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addShopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addShopSuccess: (state, action) => {
      state.isFetching = false;
      state.shops.unshift(action.payload);
    },
    addShopFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateShopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateShopSuccess: (state, action) => {
      state.isFetching = false;
      state.shops[
        state.shops.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateShop;
    },
    updateShopFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteShopStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteShopSuccess: (state, action) => {
      state.isFetching = false;
      state.shops.splice(
        state.shops.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteShopFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getShopStart,
  getShopSuccess,
  getShopFailur,
  addShopStart,
  addShopSuccess,
  addShopFailur,
  updateShopStart,
  updateShopSuccess,
  updateShopFailure,
  deleteShopStart,
  deleteShopSuccess,
  deleteShopFailure
} = shopSlice.actions;

export default shopSlice.reducer;
