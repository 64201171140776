import React,{useState,useEffect} from 'react'
import { Container, Row, Col, FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateNotificationSuccess } from '../../redux/notificationRedux';
import "./order.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchOrder } from '../../util/apis';
import { OrderList } from './OrderList';

export function Orders(props) {
    const [orders, setorders] = useState([]);
    const [fromDate, setfromDate] = useState(new Date());
    const [toDate, settoDate] = useState(new Date());
    const [vendorId, setvendorId] = useState(null);
    const [error, seterror] = useState("")
    const navigate = useNavigate()
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const shops = useSelector((state) => state.shop.shops)
    let notification = useSelector((state)=> state.notification.notification)

    const handleSubmit = (e) =>{
        e.preventDefault();
        fetchOrder(adminUser?.accessToken, adminUser?._id, fromDate, toDate, vendorId).then((res)=>{
            setorders(res?.data)
        })
    }
    

    return (
        <>
            <Container>
                <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                    {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                    <p>{notification.sms}</p>
                </div>
                <div className='custom-form'>
                    <form className='custom-card' onSubmit={handleSubmit}>
                        <Row>
                            <Col lg={3} md={4}>
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>From date</label>
                                    <DatePicker className='form-control' selected={fromDate} onChange={(date) => setfromDate(date)} />
                                </FormGroup>
                            </Col>
                            <Col lg={3} md={4}>
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>To date</label>
                                    <DatePicker className='form-control' selected={toDate} onChange={(date) => settoDate(date)} />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4}>
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>Select Uddokta</label>
                                    <select type="text" onChange={(e)=>setvendorId(e.target.value)} className='form-control'>
                                        <option>Choose Uddokta</option>
                                        {shops?.map((shop)=>(
                                            <option value={shop?._id} key={shop?._id}>{shop?.vendorname}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg={2} md={2} >
                                <FormGroup className='form-group'>
                                    <label className='mb-2'>Search</label>
                                    <Button variant='success' className='w-100' type='submit' disabled={fromDate && toDate && vendorId? false : true}>Submit</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </div>
                <OrderList orders={orders} />
            </Container>
        </>
    )
}
