import React from 'react'
import Product from '../components/Product/Product'

export function Products(props) {
    

    return (
        <>
            <Product />
        </>
    )
}
