import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function SingleBrandPhotos({image,handleDelete,upImage}) {
  return (
    <div className='single-brand-img'>
        <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/brands/${image?.brandId}/${image?.fileName}`} alt={image?.fileName} />
        <div className='brand-upload-btn'>
            <button className='brand-up-btn' type='button' onClick={()=>upImage(`brands/${image?.brandId}/${image?.fileName}`)}>Insert Image</button>
        </div>
        <button className='brand-delete-btn' onClick={() => handleDelete(image)}><FontAwesomeIcon icon={faTimes} /></button>
    </div>
  )
}
