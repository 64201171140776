import { faCheck, faAdd, faAngleLeft, faAngleRight, faFileArrowUp, faFileExcel, faPenToSquare, faSearch, faTrashCan, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState,useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap'
import '../List/list.css'
import { Link } from 'react-router-dom'
import { deleteOffer, getAllOffer, siteSetting } from '../../util/apis'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import BeatLoader from 'react-spinners/BeatLoader'
import { updateNotificationSuccess } from '../../redux/notificationRedux'

export function OrderList({orders}) {
    const [modalShow, setModalShow] = useState(false);
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [loader, setloader] = useState(false)
    const [searchedQuery, setsearchedQuery] = useState("")
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const setting = useSelector((state) => state.siteSetting.siteSetting)
    let notification = useSelector((state)=> state.notification.notification)


    const [deleteItem, setdeleteItem] = useState(null);
    const handleRemoveItem = (id) =>{
        
    }


    const displayItems = orders.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item._id.includes(searchedQuery)) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
            let date = new Date(item.createdAt)
            let subtotal = item.totalamount-item.deliveryCharge-2;
            let payable = subtotal - ((item?.storeid?.commission/100)*subtotal)
          return (
            <tr key={index}>
                <td>{item.storeid?.vendorname}</td>
                <td>{item.storeid?.vendorId}</td>
                <td>{item.orderId}</td>
                <td>{item.paymentMode}</td>
                <td>{item.totalamount-30-2}</td>
                <td>{(payable).toFixed()}</td>
                <td>{item.totalamount}</td>
            </tr>
          );
        });

    return (
        <>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            {orders.length === 0? <p className='text-center'>No Order Found</p> : 
                <div className='list'>
                    <div className='table custom-card category-table'>
                        <h5 className='d-flex justify-content-between'>Order List</h5>
                        <div className='table-top'>
                            <div className='form-group'>
                                Show
                                <select onChange={(e)=>setitemPerPage(e.target.value )}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                                entries
                            </div>
                            <div className='form-group'>
                                <label>Search:</label>
                                <input type='text' onChange={(e)=>setsearchedQuery(e.target.value)} />
                            </div>
                        </div>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Uddokta Name</th>
                                    <th>Uddokta Id</th>
                                    <th>Order Id</th>
                                    <th>Payment Type</th>
                                    <th>Sale Ammount</th>
                                    <th>Payable Ammount</th>
                                    <th>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                            </tbody>
                        </table>
                    </div>
                    <div className='list-bottom d-flex justify-content-between align-items-center'>
                        <p className='m-0'>Showing {list?.length} items</p>
                        <ReactPaginate
                            previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                            nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            activeClassName={"actvie-page"}
                        />
                    </div>
                </div>
            }
        </>
    )
}
