import adminReducer from './adminRedux'
import authReducer from './authRedux'
import categoryReducer from './categoryRedux'
import brandReducer from './brandRedux'
import productReducer from './productRedux'
import notificationReducer from './notificationRedux'
import shopReducer from './shopRedux'
import userReducer from './userRedux'
import settingReducer from "./settingRedux"
import vendorSettingReducer from "./vendorSettingRedux"
import paymentMethodReducer from "./paymentRedux"
import expenseHeadReducer from "./expenseHeadRedux"
import {configureStore,combineReducers} from "@reduxjs/toolkit"
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({admins: adminReducer,userInfo: authReducer, category: categoryReducer, brand: brandReducer, product: productReducer, notification: notificationReducer, shop: shopReducer, user: userReducer, siteSetting: settingReducer, vendorSetting: vendorSettingReducer,paymentmethod: paymentMethodReducer,expenseHead:expenseHeadReducer});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
  }),
});

export let persistor = persistStore(store);