import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree, faBoxes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Product() {
  const products = useSelector((state)=>state.product.products)
  let requestedProduct = [...products];
  requestedProduct = requestedProduct.filter(product=>product.status === false);
  return (
    <Container>
        <div className='dashboard-inner'>
                <h5 className='mt-3 mb-3'>Category</h5>
                <Row>
                    <Col lg={3}>
                        <Link to="/productlist" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Products</p>
                                <h6>{products?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/requestedproducts" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Requested Product</p>
                                <h6>{requestedProduct?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faBoxes} />
                            </div>
                        </Link>
                    </Col>
                </Row>
            </div>
    </Container>
  )
}
