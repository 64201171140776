import { faCheck, faAdd, faAngleLeft, faAngleRight, faEdit, faFileExcel, faPenToSquare, faSearch, faTrashCan, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState,useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap'
import '../List/list.css'
import { Link } from 'react-router-dom'
import { addExpenseHead, addExpenses, getAllExpenses} from '../../util/apis'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import BeatLoader from 'react-spinners/BeatLoader'
import { updateNotificationSuccess } from '../../redux/notificationRedux'
import { slugify } from '../../util/helper'

export default function ExpenseList() {
    const [formopen, setformopen] = useState(false);
    const [editForm, seteditForm] = useState(false);
    const [editValue, seteditValue] = useState({});
    const [inputs, setinputs] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch()
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const [slug, setslug] = useState()
    const [error, seterror] = useState("")
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [fromdate, setfromdate] = useState(new Date())
    const [todate, settodate] = useState(new Date())
    const [todaysDate, settodaysDate] = useState()
    const [loader, setloader] = useState(false)
    const [searchedQuery, setsearchedQuery] = useState("")
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const paymentmethod = useSelector((state) => state.paymentmethod.methods)
    const expenseHead = useSelector((state) => state.expenseHead.expenseheads)
    let notification = useSelector((state)=> state.notification.notification)

    const fetchItems = (fromdate, todate) =>{
        setloader(true)
        getAllExpenses(adminUser?.accessToken,fromdate,todate).then((res)=>{
            setlist(res.data)
        })
        setTimeout(()=>{
            setloader(false)
        }, 1000)
    }

    const onSelectDate = ( ) =>{
        if (fromdate && todate) {
            fetchItems(fromdate, todate)
        }else{
            console.log("Choose from and to date");
        }
    }

    const handleEditChange = (e) =>{
        seteditValue(prev=>{
            return{...prev, [e.target.name]:e.target.value}
        })
    }

    const handleChange = (e) =>{
        setinputs(prev=>{
            return{...prev, [e.target.name]:e.target.value}
        })
    }

    const handleUpdateFormSubmit = (e) =>{
        e.preventDefault();
        // updateExpenseHead(dispatch, adminUser?.accessToken, editValue, editValue?._id).then((res)=>{
        //     if (res.status === 200) {
        //         seteditForm(false)
        //         fetchItems()
        //         seterror("")
        //     } else {
        //         seterror("Something went wrong")
        //     }
        // })
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        let data = {...inputs}
        addExpenses(adminUser?.accessToken, data).then((res)=>{
            if (res.status === 201) {
                setformopen(false)
                dispatch(updateNotificationSuccess({sms:"Expense Added Successfully",status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                seterror("")
            } else {
                seterror(res?.data)
            }
        })
    }
    useEffect(() => {
        setloader(true)
        setTimeout(()=>{
            dispatch(updateNotificationSuccess({sms:"",status:null}))
            setloader(false)
        }, 2000)
    }, [dispatch])

    const [deleteItem, setdeleteItem] = useState(null);
    const handleRemoveItem = (id) =>{
        setModalShow(true)
        setdeleteItem(id)
    }

    const removeItem = () =>{
        // deleteOffer(adminUser.accessToken, deleteItem).then((res)=>{
        //     if (res.data) {
        //         setModalShow(false)
        //         dispatch(updateNotificationSuccess({sms:"Offer Delete Success",status:true}))
        //         setTimeout(() => {
        //             dispatch(updateNotificationSuccess({sms:"",status:null}))
        //         }, 2000);
        //         fetchCategories()
        //     } else {
        //         dispatch(updateNotificationSuccess({sms:"Offer Delete Failed",status:false}))
        //         setTimeout(() => {
        //           dispatch(updateNotificationSuccess({sms:"",status:null}))
        //         }, 2000); 
        //         setModalShow(false)
        //     }
        // })
    }

    const displayItems = list.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.name.toLowerCase().includes(searchedQuery?.toLowerCase())) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
            let expensedate = new Date(item?.expense_date)
          return (
            <tr key={index}>
                <td>{index+1}</td>
                <td>{expensedate.toDateString()}</td>
                <td>{item.expense_head?.name}</td>
                <td>{item.purpose}</td>
                <td>{item.payment_method?.name}</td>
                <td>{item.Amount}</td>
                <td>
                    <div className='action-btns'>
                        <a onClick={()=>{seteditForm(true);seteditValue(item)}}><FontAwesomeIcon icon={faPenToSquare} /></a>
                    </div>
                </td>
            </tr>
          );
    });

  return (
    <Container>
        <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
            {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
            <p>{notification.sms}</p>
        </div>
        <Modal
            show={editForm}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                Edit Expense Head
                <FontAwesomeIcon icon={faTimes} onClick={()=>seteditForm(false)} />
            </Modal.Header>
            <Modal.Body>
                <form className='theme-form' onSubmit={handleUpdateFormSubmit}>
                    <Row>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Expense Head Name</label>
                                <input type='text' name='name' placeholder='e.g. Salary' value={editValue?.name} onChange={(e)=>handleEditChange(e)} className='form-control' autoComplete='off' />
                            </div>
                        </div>
                        <div className='col-lg-12 d-flex justify-content-end mt-3'>
                            <button type='submit' className='btn theme-btn'>Save</button>
                        </div>
                        <p className='alert'>{error}</p>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
        <Modal
            show={formopen}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                Add Expense Head
                <FontAwesomeIcon icon={faTimes} onClick={()=>setformopen(false)} />
            </Modal.Header>
            <Modal.Body>
                <form className='custom-form' onSubmit={handleSubmit}>
                    <Row>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Expense Head</label>
                                <select className='form-control' name='expense_head' onChange={(e)=>handleChange(e)}>
                                    <option>Select One</option>
                                    {expenseHead?.map((item)=>(
                                        <option value={item?._id}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Select payment method</label>
                                <select className='form-control' name='payment_method' onChange={(e)=>handleChange(e)}>
                                    <option>Select One</option>
                                    {paymentmethod?.map((item)=>(
                                        <option value={item?._id}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Date</label>
                                <input type='date' className='form-control' name='expense_date' onChange={(e)=>handleChange(e)} />
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Ammount</label>
                                <input type='number' name='Amount' placeholder='e.g. 10000' onChange={(e)=>handleChange(e)} className='form-control' autoComplete='off' />
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Purpose</label>
                                <input type='text' name='purpose' placeholder='e.g. for lunch' onChange={(e)=>handleChange(e)} className='form-control' autoComplete='off' />
                            </div>
                        </div>
                        <div className='col-lg-12 d-flex justify-content-end mt-3'>
                            <button type='reset' className='btn theme-btn-alt'>Reset</button>
                            <button type='submit' className='btn theme-btn'>Save</button>
                        </div>
                        <p className='alert'>{error}</p>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
        <Modal
            show={modalShow}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='popup-modal'
            >
            <Modal.Body>
                <FontAwesomeIcon icon={faCheck} />
                <h4>Are you sure?</h4>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                        <Button variant='danger' onClick={()=>setModalShow(false)} className='w-100 mt-4'>Cancel</Button>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <Button variant='success' onClick={()=>removeItem()} className='w-100 mt-4'>Submit</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <div className='list'>
            <div className='table custom-card category-table'>
                <h5 className='d-flex justify-content-between'>Expenses <button onClick={()=>setformopen(true)} className='btn btn-primary w-md waves-effect waves-light'>Add Expense</button></h5>
                <div className='table-top d-block'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='input-group d-flex'>
                                <div className='form-group d-flex align-items-center' style={{marginLeft:'15px'}}>
                                    <label style={{marginRight:'10px'}}>From:</label>
                                    <input type='date' className='form-control' value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                </div>
                                <div className='form-group d-flex align-items-center' style={{marginLeft:'15px'}}>
                                    <label style={{marginRight:'10px'}}>To:</label>
                                    <input type='date' className='form-control' value={todate} onChange={(e) => settodate(e.target.value)} />
                                </div>
                                <button className='btn theme-btn' onClick={onSelectDate}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Expense Head</th>
                            <th>Purpose</th>
                            <th>Payment</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : list.length>0? displayItems : "No Expenses Available"}
                    </tbody>
                </table>
            </div>
            <div className='list-bottom d-flex justify-content-between align-items-center'>
                <p className='m-0'>Showing {list?.length} items</p>
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    activeClassName={"actvie-page"}
                />
            </div>
        </div>
    </Container>
  )
}
