import React from 'react'
import { Dash } from '../components/Dashboard/dashboard'

export function Dashboard(props) {
    

    return (
        <>
            <Dash />
        </>
    )
}
