import React from 'react'
import AddSection from '../components/Section/AddSection'

export default function Section() {
  return (
    <>
      <AddSection />
    </>
  )
}
