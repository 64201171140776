import React from 'react'
import { Orders } from '../components/Orders/Orders'

export function Order(props) {
    

    return (
        <>
            <Orders/>
        </>
    )
}
