import { faAdd, faAngleLeft, faAngleRight, faCheck, faFileArrowUp, faFileExcel, faPenToSquare, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap'
import fruits from '../../imgs/fruits.png'
import vegetables from '../../imgs/vegetabls.png'
import bbq from '../../imgs/bbq-assen.png'
import '../List/list.css'
import { Link } from 'react-router-dom'
import { deleteAdmin, getAllAdmin } from '../../util/apis'
import { useDispatch,useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import BeatLoader from 'react-spinners/BeatLoader'

export default function AdminList() {
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch()
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [loader, setloader] = useState(false)
    const [searchedQuery, setsearchedQuery] = useState("")
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    
    const fetchAdmins = () =>{
        getAllAdmin(dispatch,adminUser.accessToken).then((res)=>{
            setlist(res.data)
        })
    }

    useEffect(() => {
        setloader(true)
        setTimeout(()=>{
            setloader(false)
        }, 2000)
        fetchAdmins()
    }, [dispatch])

    const [deleteItem, setdeleteItem] = useState(null);
    const handleRemoveItem = (id) =>{
        setModalShow(true)
        setdeleteItem(id)
    }

    const removeItem = () =>{
        deleteAdmin(dispatch, adminUser.accessToken, deleteItem).then((res)=>{
            if (res.data) {
                setModalShow(false)
                fetchAdmins()
            } else {
                setModalShow(false)
            }
        })
    }

    const displayItems = list?.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.username.toLowerCase().includes(searchedQuery?.toLowerCase())) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
          return (
            <tr key={index}>
                <td>
                    <img src={`${process.env.REACT_APP_URL}alluploads/${item.img}`} alt="" />
                </td>
                <td>{item.fullname}</td>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.role}</td>
                <td>
                    <div className='action-btns'>
                        <a><FontAwesomeIcon icon={faTrashCan} onClick={()=>handleRemoveItem(item._id)}/></a>
                    </div>
                </td>
            </tr>
          );
        });

    return (
        <Container>
            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='popup-modal'
                >
                <Modal.Body>
                    <FontAwesomeIcon icon={faCheck} />
                    <h4>Are you sure?</h4>
                    <Row>
                        <Col lg={6} md={6} sm={6}>
                            <Button variant='danger' onClick={()=>setModalShow(false)} className='w-100 mt-4'>Cancel</Button>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Button variant='success' onClick={()=>removeItem()} className='w-100 mt-4'>Submit</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <div className='list'>
                <div className='table custom-card category-table'>
                    <h5 className='d-flex justify-content-between'>Admins <Link to="/addadmin" className='btn btn-primary w-md waves-effect waves-light'>Add Admin</Link></h5>
                    <div className='table-top'>
                        <div className='form-group'>
                            Show
                            <select onChange={(e)=>setitemPerPage(e.target.value )}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                            </select>
                            entries
                        </div>
                        <div className='form-group'>
                            <label>Search:</label>
                            <input type='text' onChange={(e)=>setsearchedQuery(e.target.value)} />
                        </div>
                    </div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Role</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        activeClassName={"actvie-page"}
                    />
            </div>
        </Container>
    )
}
