import React, { useEffect, useState } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree, faBoxes, faPhotoFilm } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getAllOffer } from '../../util/apis'

export default function Offer() {
    const [list, setlist] = useState([])
    const fetchCategories = () =>{
        getAllOffer().then((res)=>{
            setlist(res.data)
        })
    }
    useEffect(() => {
        fetchCategories()
    }, [])
    let uddoktaOffers = [...list];
    uddoktaOffers = uddoktaOffers.filter(offer=>offer.givento === 'vendor');
    let userOffers = [...list];
    userOffers = userOffers.filter(offer=>offer.givento === 'user');
  return (
    <Container>
        <div className='dashboard-inner'>
                <h5 className='mt-3 mb-3'>Offers</h5>
                <Row>
                    <Col lg={3}>
                        <Link to="/offerlist" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Uddokta Offers</p>
                                <h6>{uddoktaOffers?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/offerlist" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>User Offers</p>
                                <h6>{userOffers?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </Link>
                    </Col>
                </Row>
            </div>
    </Container>
  )
}
