import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faUsers, faBoxes, faTree, faShop } from '@fortawesome/free-solid-svg-icons'
import { updateNotificationSuccess } from '../../redux/notificationRedux'
import "./dashboard.css"
import { getAllCategory, getAllPaymentMethod, getAllProduct, getAllShops, getAllUsers } from '../../util/apis'

export function Dash(props) {
    const dispatch = useDispatch()
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    let notification = useSelector((state)=> state.notification.notification)
    let users = useSelector((state)=> state.user.users)
    let products = useSelector((state)=> state.product.products)
    let categories = useSelector((state)=> state.category.categories)
    let shops = useSelector((state)=> state.shop.shops)
    
    useEffect(() => {
        setTimeout(() => {
            dispatch(updateNotificationSuccess({sms:"",status:null}))
          }, 2000);
          getAllUsers(dispatch, adminUser?.accessToken)
          getAllProduct(dispatch)
          getAllCategory(dispatch)
          getAllShops(dispatch)
          getAllPaymentMethod(adminUser?.accessToken, dispatch)
    }, [])

    return (
        <Container>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            <div className='dashboard-inner'>
                <h5 className='mt-3 mb-3'>Dashboard</h5>
                <Row>
                    <Col lg={3}>
                        <div className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Users</p>
                                <h6>{users?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faUsers} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Products</p>
                                <h6>{products.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faBoxes} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Category</p>
                                <h6>{categories.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Shop</p>
                                <h6>{shops.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faShop} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
