import jwtDecode from "jwt-decode";

export const isAuthenticated = (jwt) =>{
    if (typeof window == 'undefined') return false;
    if (jwt) {
        const { exp } = jwtDecode(jwt);
        return (new Date).getTime() < exp * 1000;
    }else return false;
}

export const slugify = (string) => {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return string?.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) 
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^(\u0980-\u09FF)\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
  }