import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../List/list.css'
import { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getAllAds } from '../../util/apis'
import { updateNotificationSuccess } from '../../redux/notificationRedux'
import BeatLoader from 'react-spinners/BeatLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate'

export default function CurrentAds() {
    const dispatch = useDispatch()
    const [modalShow, setModalShow] = useState(false);
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [searchedQuery, setsearchedQuery] = useState("")
    const [loader, setloader] = useState(false)
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    let notification = useSelector((state)=> state.notification.notification)
    const status = "active"

    const fetchCurrentAds = () =>{
        getAllAds(status, adminUser?._id, adminUser?.accessToken).then((res)=>{
            setlist(res.data)
        })
    }

    useEffect(() => {
        setloader(true)
        setTimeout(()=>{
            dispatch(updateNotificationSuccess({sms:"",status:null}))
            setloader(false)
        }, 2000)
        fetchCurrentAds()
    }, [])

    const displayItems = list.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.brandName.toLowerCase().includes(searchedQuery?.toLowerCase())) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
          return (
            <tr key={index}>
                <td>
                    <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/${item.imgurl}`} alt="" />
                </td>
                <td>{item.title}</td>
                <td style={{textTransform:"capitalize"}}>{item.place}</td>
                <td>{item.position}</td>
                <td>{item?.hostedBy?.vendorname}</td>
                <td>৳ {item?.maxBidLimit}</td>
                <td>
                    <div className='action-btns'>
                        {/* <a onClick={()=>handleRemoveItem(item._id)}><FontAwesomeIcon icon={faTrashCan} /></a> */}
                        {/* <Link to={`/updatebrand/${item._id}`}><FontAwesomeIcon icon={faPenToSquare} /></Link> */}
                    </div>
                </td>
            </tr>
          );
        });

  return (
    <Container>
        <div className='list'>
            <div className='table custom-card category-table'>
                <h5 className='d-flex justify-content-between'>Current Ads </h5>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Title</th>
                            <th>Place</th>
                            <th>Position</th>
                            <th>Hosted By</th>
                            <th>Sold In</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                    </tbody>
                </table>
            </div>
            <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        activeClassName={"actvie-page"}
                    />
        </div>
    </Container>
  )
}
