import React, { useEffect } from 'react'
import { useState } from 'react';
import './media.css'
import { addMedia, deleteMedia, deleteMediaFromStorage, deleteMultiMedia, deleteMultiMediaFromStorage, loadMedia, uploadMedia } from '../../util/apis';
import { useSelector } from 'react-redux';
import ImageList from './ImageList';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BrandMediaFolder from './BrandMediaFolder';

export default function Media({uploadImg}) {
  let files = [];
  let library = {};
  const [upImg, setupImg] = useState(false);
  const [mediaLibrary, setmediaLibrary] = useState(true);
  const [sliders, setsliders] = useState(false);
  const [brandFolders, setbrandFolders] = useState(false);
  const [uploader, setuploader] = useState(false);
  const adminUser = useSelector((state) => state.userInfo.currentUser)
  const brandAlbums = useSelector((state) => state.brand.brands)
  const [urls, setUrls] = useState([]);
  const [medias, setmedias] = useState([]);
  useEffect(() => {
    loadMedia().then((res=>{
      setmedias(res.data)
    }))
  }, [])
  let sliderLibrary = [...medias];
  sliderLibrary = sliderLibrary.filter(img=>img.fileTo === 'banner');

  const loadtotalMedia = () =>{
    loadMedia().then((res)=>{
      setmedias(res.data)
    })
  }

  

  const handleUpimg = () => {
    setupImg(true);
    setmediaLibrary(false);
  };

  const upImage = (fileName) => {
    // const imgUrl = `${process.env.REACT_APP_BASE_URL}/api/alluploads/${fileName}`;
    uploadImg(fileName);
  };

  const handleMediaLibrary = () => {
    setupImg(false);
    setmediaLibrary(true);
    setbrandFolders(false)
  };
  const handleBrandFolders = () => {
    setupImg(false);
    setmediaLibrary(false);
    setbrandFolders(true)
  };

  const handleUpload = (e) => {
    setuploader(true)
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== 'image/jpeg' && files[i].type !== 'image/png' && files[i].type !== 'image/jpg' && files[i].type !== 'image/pdf') {
        console.log("File is not in expected format");
      }else{
        console.log("File is in expected format");
        library = { fileName: files[i].name, fileType: files[i].type };
        addMedia(adminUser?.accessToken, library)
        .then((res)=>{
          console.log("Media added to database successfully");
        })
        .catch((e) => {
          console.log("error", e);
        });
        setUrls((prevState) => [...prevState, library.fileName]);
        console.log("File append");
        data.append("file", files[i]);
      }
    }

    uploadMedia(adminUser?.accessToken, data, adminUser?._id)
    .then(() => {
        console.log("Media uploaded to server successfully");
    })
    .catch((e) => {
        console.log("error", e);
    })
    .then(() => {
        loadMedia().then((res=>{
            setmedias(res.data)
        }))
        setuploader(false)
        setmediaLibrary(true)
        setupImg(false)
    });
  }

  const handleDelete = (imagename) => {
      deleteMedia(adminUser?.accessToken, imagename._id)
      .then((res) => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

      deleteMediaFromStorage(adminUser?.accessToken, imagename.fileName)
      .then((res) => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => {
        loadMedia().then((res=>{
            setmedias(res.data)
        }))
      });
  };

  const [selectedMedias, setselectedMedias] = useState([])
  const handleCheck = (e, info) => {
      var updatedList = [...selectedMedias];
      if (e.target.checked) {
          updatedList = [...selectedMedias, info];
      } else {
          updatedList = selectedMedias?.filter((item)=>{
            return item?._id !== info?._id
          })
      }
      setselectedMedias(updatedList);
  };

  const handleMultiDelete = (imagename) => {
    deleteMultiMedia(adminUser?.accessToken, selectedMedias)
    .then((res) => {
      console.log("success");
    })
    .catch((e) => {
      console.log("error", e);
    });

    deleteMultiMediaFromStorage(adminUser?.accessToken, selectedMedias)
    .then((res) => {
      console.log("success");
    })
    .catch((e) => {
      console.log("error", e);
    })
    .then(() => {
      loadMedia().then((res=>{
          setselectedMedias([])
          setmedias(res.data)
      }))
    });
};

  return (
    <Container className='h-100'>
      <div className='list h-100'>
        <div className='table custom-card category-table h-100' style={{position:"relative"}}>
          <ul className='media-header mt-0'>
              <li className={mediaLibrary? 'active' : ''} onClick={handleMediaLibrary}>All <span>{medias.length - sliderLibrary.length}</span></li>
              {/* <li className={sliders? 'active' : ''} onClick={handleSliders}>Banners <span>{sliderLibrary.length}</span></li> */}
              <li className={brandFolders? 'active' : ''} onClick={handleBrandFolders}>Albums <span>{brandAlbums?.length}</span></li>
          </ul>
          <div className='mediabody'>
              {/* <div className='mediawrap upload' style={upImg? {display:"flex"} : {display:"none"}}>
                  <form>
                      <h1>Drop files anywhere to upload</h1>
                      <p>Or</p>
                      <h4>Select Files</h4>
                      <input type='file' multiple onChange={handleUpload}/>
                  </form>
              </div> */}
              <div className='mediawrap' style={mediaLibrary? {display:"flex"} : {display:"none"}}>
                <button className='btn btn-primary w-md waves-effect waves-light' onClick={handleMultiDelete} disabled={selectedMedias.length? false : true}>Delete Selected</button>
                <div className='image-list'>
                  <div class="single-upload-img"><form><h1>Drop Files Here To Upload</h1><p>Or</p><input type="file" onChange={handleUpload} multiple/><a>Select Files</a></form></div>
                  {medias?.filter(image=>image.fileFor !== 'vendor' && image.brandId === undefined).map((image)=>(
                    <ImageList image={image} upImage={upImage} handleDelete={handleDelete} key={image?._id} handleCheck={handleCheck} />
                  ))}
                </div>
              </div>
              <div className='mediawrap' style={brandFolders? {display:"flex"} : {display:"none"}}>
                <div className='image-list'>
                  {brandAlbums?.map((folder)=>(
                    <BrandMediaFolder upImage={upImage} folder={folder} medias={medias} loadtotalMedia={loadtotalMedia} key={folder?._id} />
                  ))}
                </div>
              </div>
          </div>
        </div>
      </div>
      {/* <div className='mediamain'>
          <ul className='media-header'>
              <li className={upImg? 'active' : ''} onClick={handleUpimg}>Upload</li>
              <li className={mediaLibrary? 'active' : ''} onClick={handleMediaLibrary}>Media Library</li>
          </ul>
          <div className='mediabody'>
              <div className='mediawrap upload' style={upImg? {display:"flex"} : {display:"none"}}>
                  <form>
                      <h1>Drop files anywhere to upload</h1>
                      <p>Or</p>
                      <h4>Select Files</h4>
                      <input type='file' multiple onChange={handleUpload}/>
                  </form>
              </div>
              <div className='mediawrap' style={mediaLibrary? {display:"flex"} : {display:"none"}}>
                  <ImageList media={medias} upImage={upImage} handleDelete={handleDelete} />
              </div>
          </div>
      </div> */}
    </Container>
  )
}
