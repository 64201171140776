import './App.css';
import {BrowserRouter, Routes, Route}  from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dashboard } from './pages/dashboard';
import { Products } from './pages/products';
import { Categories } from './pages/categories';
import { Brands } from './pages/brands';
import { Order } from './pages/order';
import { Shop } from './pages/shop';
import { Navbar } from './components/Navbar/Navbar';
import { AddCategory } from './components/Category/AddCategory';
import { Admin } from './pages/admin';
import { AddAdmin } from './components/Admins/AddAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { Login } from './pages/login';
import { UpdateCategory } from './components/Category/UpdateCategory';
import { AddBrand } from './components/Brand/AddBrand';
import { UpdateBrand } from './components/Brand/UpdateBrand';
import { AddProduct } from './components/Product/AddProduct';
import { UpdateProduct } from './components/Product/UpdateProduct';
import { useEffect, useState } from 'react';
import Medias from './pages/media';
import { AddShop } from './components/Shop/AddShop';
import { Updateshop } from './components/Shop/UpdateShop';
import { User } from './pages/user';
import Settings from './pages/setting';
import { getAllBrand, siteSetting, vendorsiteSetting } from './util/apis';
import { Uddoktasetting } from './pages/uddoktasetting';
import { RequestedProducts } from './components/Product/RequestedProducts';
import { Maincategory } from './components/Category/Maincategory';
import { Subcategory } from './components/Category/Subcategory';
import { CategoryList } from './components/Category/CategoryList';
import { ProductList } from './components/Product/ProductList';
import { Restaurents } from './components/Shop/Restaurents';
import { ShopList } from './components/Shop/ShopList';
import { Supermarket } from './components/Shop/Supermarket';
import { Fashion } from './components/Shop/Fashion';
import { GraphicsCategory } from './components/Category/GraphicsCategory';
import { isAuthenticated } from './util/helper';
import { logout } from './redux/authRedux';
import { AddSlider } from './components/Sliders/AddSlider';
import { SliderList } from './components/Sliders/SliderList';
import { VoucherList } from './components/Voucher/VoucherList';
import { Addvoucher } from './components/Voucher/Addvoucher';
import { AddOffer } from './components/Offer/AddOffer';
import { OfferList } from './components/Offer/OfferList';
import Offer from './components/Offer/Offer';
import PaymentMethod from './components/PaymentMethod/PaymentMethod';
import ExpenseHead from './components/Expense/ExpenseHead';
import ExpenseList from './components/Expense/ExpenseList';
import { PendingShops } from './components/Shop/PendingShops';
import CurrentAds from './components/Ads/CurrentAds';
import Inbiding from './components/Ads/Inbiding';
import Section from './pages/section';

function App() {
  const dispatch = useDispatch()
  const adminUser = useSelector((state) => state.userInfo.currentUser)
  const sessionexpire = () =>{
    isAuthenticated(adminUser?.accessToken)? console.log("Connected") : dispatch(logout());
  }

  useEffect(() => {
      siteSetting(dispatch)
      vendorsiteSetting(dispatch)
      getAllBrand(dispatch)
      sessionexpire()
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        {adminUser?
          <>
            {/* <Sidebar /> */}
            <div className='content-area'>
              <Navbar />
              <div className='content-area-inner'>
                {adminUser?.role === 'superadmin'? (
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/products" element={<Products/>} />
                    <Route path="/category" element={<Categories/>} />
                    <Route path="/brand" element={<Brands/>} />
                    <Route path="/orders" element={<Order/>} />
                    <Route path="/shops" element={<Shop/>} />
                    <Route path="/addshop" element={<AddShop/>} />
                    <Route path="/addcategory" element={<AddCategory/>} />
                    <Route path="/addbrand" element={<AddBrand/>} />
                    <Route path="/addproduct" element={<AddProduct/>} />
                    <Route path="/admins" element={<Admin/>} />
                    <Route path="/addadmin" element={<AddAdmin/>} />
                    <Route path="/updatecategory/:id" element={<UpdateCategory/>} />
                    <Route path="/updatebrand/:id" element={<UpdateBrand/>} />
                    <Route path="/updateproduct/:id" element={<UpdateProduct/>} />
                    <Route path="/updateshop/:id" element={<Updateshop/>} />
                    <Route path="/medias" element={<Medias/>} />
                    <Route path="/users" element={<User/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/vendorsettings" element={<Uddoktasetting/>} />
                    <Route path="/requestedproducts" element={<RequestedProducts/>} />
                    <Route path="/maincategory" element={<Maincategory/>} />
                    <Route path="/subcategory" element={<Subcategory/>} />
                    <Route path="/allcategory" element={<CategoryList/>} />
                    <Route path="/productlist" element={<ProductList/>} />
                    <Route path="/restaurent" element={<Restaurents/>} />
                    <Route path="/shoplist" element={<ShopList/>} />
                    <Route path="/requestedshop" element={<PendingShops/>} />
                    <Route path="/supermarket" element={<Supermarket/>} />
                    <Route path="/fashion" element={<Fashion/>} />
                    <Route path="/graphicscategory" element={<GraphicsCategory/>} />
                    <Route path="/addslider" element={<AddSlider/>} />
                    <Route path="/sliderlist" element={<SliderList/>} />
                    <Route path="/voucherlist" element={<VoucherList/>} />
                    <Route path="/addvoucher" element={<Addvoucher/>} />
                    <Route path="/addoffer" element={<AddOffer/>} />
                    <Route path="/offer" element={<Offer/>} />
                    <Route path="/offerlist" element={<OfferList/>} />
                    <Route path="/paymentmethod" element={<PaymentMethod/>} />
                    <Route path="/expense_head" element={<ExpenseHead/>} />
                    <Route path="/expense" element={<ExpenseList/>} />
                    <Route path="/ads" element={<CurrentAds/>} />
                    <Route path="/inbiding" element={<Inbiding/>} />
                    <Route path="/section" element={<Section/>} />
                  </Routes>
                ) : adminUser?.role === 'admin'? (
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/products" element={<Products/>} />
                    <Route path="/category" element={<Categories/>} />
                    <Route path="/brand" element={<Brands/>} />
                    <Route path="/addcategory" element={<AddCategory/>} />
                    <Route path="/addbrand" element={<AddBrand/>} />
                    <Route path="/addproduct" element={<AddProduct/>} />
                    <Route path="/updatecategory/:id" element={<UpdateCategory/>} />
                    <Route path="/updatebrand/:id" element={<UpdateBrand/>} />
                    <Route path="/updateproduct/:id" element={<UpdateProduct/>} />
                    <Route path="/updateshop/:id" element={<Updateshop/>} />
                    <Route path="/medias" element={<Medias/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/requestedproducts" element={<RequestedProducts/>} />
                    <Route path="/maincategory" element={<Maincategory/>} />
                    <Route path="/subcategory" element={<Subcategory/>} />
                    <Route path="/allcategory" element={<CategoryList/>} />
                    <Route path="/productlist" element={<ProductList/>} />
                    <Route path="/restaurent" element={<Restaurents/>} />
                    <Route path="/shoplist" element={<ShopList/>} />
                    <Route path="/requestedshop" element={<PendingShops/>} />
                    <Route path="/supermarket" element={<Supermarket/>} />
                    <Route path="/fashion" element={<Fashion/>} />
                    <Route path="/graphicscategory" element={<GraphicsCategory/>} />
                    <Route path="/ads" element={<CurrentAds/>} />
                    <Route path="/inbiding" element={<Inbiding/>} />
                    <Route path="/section" element={<Section/>} />
                  </Routes>
                ) : adminUser?.role === 'manager'? (
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard/>} />
                    <Route path="/products" element={<Products/>} />
                    <Route path="/category" element={<Categories/>} />
                    <Route path="/brand" element={<Brands/>} />
                    <Route path="/addcategory" element={<AddCategory/>} />
                    <Route path="/addbrand" element={<AddBrand/>} />
                    <Route path="/addproduct" element={<AddProduct/>} />
                    <Route path="/updatecategory/:id" element={<UpdateCategory/>} />
                    <Route path="/updatebrand/:id" element={<UpdateBrand/>} />
                    <Route path="/updateproduct/:id" element={<UpdateProduct/>} />
                    <Route path="/updateshop/:id" element={<Updateshop/>} />
                    <Route path="/medias" element={<Medias/>} />
                    <Route path="/requestedproducts" element={<RequestedProducts/>} />
                    <Route path="/maincategory" element={<Maincategory/>} />
                    <Route path="/subcategory" element={<Subcategory/>} />
                    <Route path="/allcategory" element={<CategoryList/>} />
                    <Route path="/productlist" element={<ProductList/>} />
                    <Route path="/restaurent" element={<Restaurents/>} />
                    <Route path="/shoplist" element={<ShopList/>} />
                    <Route path="/requestedshop" element={<PendingShops/>} />
                    <Route path="/supermarket" element={<Supermarket/>} />
                    <Route path="/fashion" element={<Fashion/>} />
                    <Route path="/graphicscategory" element={<GraphicsCategory/>} />
                    <Route path="/section" element={<Section/>} />
                  </Routes>
                ) : ""}
              </div>
            </div>
          </>
          :
          <Login/>
        }
      </BrowserRouter>
    </div>
  );
}

export default App;
