import React from 'react'
import Category from '../components/Category/Category'

export function Categories(props) {
    

    return (
        <>
            <Category/>
        </>
    )
}
