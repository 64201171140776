import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../List/list.css'
import { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { createAd, getAllAds } from '../../util/apis'
import { updateNotificationSuccess } from '../../redux/notificationRedux'
import BeatLoader from 'react-spinners/BeatLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import adbanner from '../../imgs/uddoktabanner.jpg'
import uddoktathumbnail from '../../imgs/icon-192x192.png'
import SingleCampaingItem from './SingleCampaingItem'
import { Modal } from 'react-bootstrap'

export default function Inbiding() {
    const dispatch = useDispatch()
    const [error, seterror] = useState("")
    const [modalShow, setModalShow] = useState(false);
    const [formopen, setformopen] = useState(false);
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [searchedQuery, setsearchedQuery] = useState("")
    const [loader, setloader] = useState(false)
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    let notification = useSelector((state)=> state.notification.notification)
    const status = "inbiding"

    const fetchCurrentAds = () =>{
        getAllAds(status, adminUser?._id, adminUser?.accessToken).then((res)=>{
            setlist(res.data)
        })
    }

    useEffect(() => {
        setloader(true)
        setTimeout(()=>{
            dispatch(updateNotificationSuccess({sms:"",status:null}))
            setloader(false)
        }, 2000)
        fetchCurrentAds()
    }, [])

    const displayItems = list.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.brandName.toLowerCase().includes(searchedQuery?.toLowerCase())) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
            let lastTimeOfBid = new Date(item?.bidingTimeLimit)
            let adsLimitTime = new Date(item?.adsTimeLimit)
          return (
            <tr key={index}>
                <SingleCampaingItem item={item} lastTimeOfBid={lastTimeOfBid} adsLimitTime={adsLimitTime} fetchCurrentAds={fetchCurrentAds} />
            </tr>
          );
    });

    const [inputs, setinputs] = useState({});
    const handleChange = (e) =>{
        setinputs(prev=>{
            return{...prev, [e.target.name]:e.target.value}
        })
    }
    const handleSubmit = (e) =>{
        e.preventDefault()
        let data = {...inputs, position:inputs.place+'_'+inputs.position}
        createAd(adminUser?.accessToken, data).then((res)=>{
            if (res.status === 201) {
                fetchCurrentAds()
                setformopen(false)
                setinputs({})
            }else{
                seterror(res.response.data.keyPattern)
            }
        })
    }

  return (
    <>
    <Modal
        show={formopen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header>
            Create Ad Campaign
            <FontAwesomeIcon icon={faTimes} style={{cursor:'pointer'}} onClick={()=>setformopen(false)} />
        </Modal.Header>
        <Modal.Body>
            <form className='custom-form' onSubmit={handleSubmit}>
                <Row>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Ad Title</label>
                            <input type='text' className='form-control' name='title' onChange={(e)=>handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Ad Place</label>
                            <select className='form-control' name='place' onChange={(e)=>handleChange(e)}>
                                <option value="">Select Ad Place</option>
                                <option value="homepage_slider">Homepage Slider</option>
                                <option value="restaurent_top_slider">Restaurent Top Slider</option>
                                <option value="restaurent_banner_slider">Restaurent Banner Slider</option>
                                <option value="restaurent_offer_slider">Restaurent Offer Slider</option>
                                <option value="grocery_top_slider">Grocery Top Slider</option>
                                <option value="grocery_banner_slider">Grocery Banner Slider</option>
                                <option value="grocery_offer_slider">Grocery Offer Slider</option>
                                <option value="lifestyle_top_slider">Lifestyle Top Slider</option>
                                <option value="lifestyle_banner_slider">Lifestyle Banner Slider</option>
                                <option value="lifestyle_offer_slider">Lifestyle Offer Slider</option>
                                <option value="signin_slider">Signin Slider</option>
                                <option value="signup_slider">Signup Slider</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Ad Position</label>
                            <input type='number' className='form-control' name='position' onChange={(e)=>handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Min Budget</label>
                            <input type='number' className='form-control' name='minBidLimit' onChange={(e)=>handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Bid Expire In</label>
                            <input type='date' className='form-control' name='bidingTimeLimit' onChange={(e)=>handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                            <label>Ad Expire In</label>
                            <input type='date' className='form-control' name='adsTimeLimit' onChange={(e)=>handleChange(e)} />
                        </div>
                    </div>
                    <div className='col-lg-12 d-flex justify-content-end mt-3'>
                        <button type='reset' className='btn theme-btn-alt' style={{marginRight:'5px'}}>Reset</button>
                        <button type='submit' className='btn theme-btn'>Save</button>
                    </div>
                    <p className='alert' style={{color:'red'}}>{error.position === 1? "This position already taken" : ""}</p>
                </Row>
            </form>
        </Modal.Body>
    </Modal>
    <Container>
        <div className='list'>
            <div className='table custom-card category-table'>
                <h5 className='d-flex justify-content-between'>Inbiding Ads <a onClick={()=>setformopen(true)} className='btn btn-primary w-md waves-effect waves-light'>Create Ad Campaign</a></h5>
                <table className='table'>
                    <tbody>
                        {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                    </tbody>
                </table>
            </div>
        </div>
    </Container>
    </>
  )
}
