import { createSlice } from "@reduxjs/toolkit";


const vendorSettingSlice = createSlice({
  name: "vendorsettings",
  initialState: {
    vendorsettings: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    vendorSettingStart: (state) => {
      state.isFetching = true;
    },
    vendorSettingSuccess: (state, action) => {
      state.isFetching = false;
      state.vendorsettings = action.payload;
    },
    vendorSettingFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    }
  },
});

export const { vendorSettingStart, vendorSettingSuccess, vendorSettingFailure } = vendorSettingSlice.actions;
export default vendorSettingSlice.reducer;