import { createSlice } from "@reduxjs/toolkit";

export const expenseHeadSlice = createSlice({
  name: "expensehead",
  initialState: {
    expenseheads: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getExpenseHeadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getExpenseHeadSuccess: (state, action) => {
      state.isFetching = false;
      state.expenseheads = action.payload;
    },
    getExpenseHeadFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addExpenseHeadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addExpenseHeadSuccess: (state, action) => {
      state.isFetching = false;
      state.expenseheads.unshift(action.payload);
    },
    addExpenseHeadFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateExpenseHeadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateExpenseHeadSuccess: (state, action) => {
      state.isFetching = false;
      state.expenseheads[
        state.expenseheads.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateExpenseHead;
    },
    updateExpenseHeadFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteExpenseHeadStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteExpenseHeadSuccess: (state, action) => {
      state.isFetching = false;
      state.expenseheads.splice(
        state.expenseheads.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteExpenseHeadFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getExpenseHeadStart,
  getExpenseHeadSuccess,
  getExpenseHeadFailur,
  addExpenseHeadStart,
  addExpenseHeadSuccess,
  addExpenseHeadFailur,
  updateExpenseHeadStart,
  updateExpenseHeadSuccess,
  updateExpenseHeadFailure,
  deleteExpenseHeadStart,
  deleteExpenseHeadSuccess,
  deleteExpenseHeadFailure
} = expenseHeadSlice.actions;

export default expenseHeadSlice.reducer;
