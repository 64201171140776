import React from 'react'
import { Brand } from '../components/Brand/Brand'

export function Brands() {
  return (
    <>
        <Brand/>
    </>
  )
}
