import React,{useState} from 'react'
import logo from '../imgs/logo.png'
import { Button } from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../util/apis';
import { updateNotificationSuccess } from '../redux/notificationRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faKey, faLock, faTimes, faUserAlt } from '@fortawesome/free-solid-svg-icons';
export function Login(props) {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState()
    const [error, seterror] = useState("")
    let notification = useSelector((state)=> state.notification.notification)

    const handleClick = (e) =>{
        e.preventDefault()
        login(dispatch,{username,password})
        .then(res => {
            if (res === undefined) {
                seterror("Wrong Credintial");
            }else{
                dispatch(updateNotificationSuccess({sms:"Login Success",status:true}))
                navigate('/dashboard')
            }
        });
    }

    return (
        <div className="container-fluid p-0">
            <div className="row g-0">
                <div className="col-lg-4">
                    <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                        <div className="w-100">
                            <div className="row justify-content-center">
                                <div className="col-lg-9">
                                    <div>
                                        <div className="text-center">
                                            <div>
                                                <a href="index.html" className="">
                                                    <img src="../imgs/logo.png" alt="" height="20" className="auth-logo logo-light mx-auto"/>
                                                </a>
                                            </div>

                                            <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                        </div>

                                        <div className="p-2 mt-5">
                                            <form onSubmit={handleClick}>
                
                                                <div className="mb-3 auth-form-group-custom mb-4">
                                                    <FontAwesomeIcon icon={faUserAlt} className='auti-custom-input-icon' />
                                                    <label htmlFor="username">Username</label>
                                                    <input type="text" className="form-control" onChange={e=>setUsername(e.target.value)} id="username" placeholder="Enter username"/>
                                                </div>
                        
                                                <div className="mb-3 auth-form-group-custom mb-4">
                                                    <FontAwesomeIcon icon={faKey} className='auti-custom-input-icon' />
                                                    <label htmlFor="userpassword">Password</label>
                                                    <input type="password" className="form-control" onChange={e=>setPassword(e.target.value)} id="userpassword" placeholder="Enter password"/>
                                                </div>
                                                <p style={{color:"red"}}>{error}</p>
                                                <div className="mt-4 text-center">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <a href="auth-recoverpw.html" className="text-muted"> <FontAwesomeIcon icon={faLock} /> Forgot your password?</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8" style={{position:"relative"}}>
                    <div className="authentication-bg">
                        <div className="bg-overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
