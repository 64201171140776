import React,{useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleArrowDown, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import adbanner from '../../imgs/uddoktabanner.jpg'
import uddoktathumbnail from '../../imgs/icon-192x192.png'
import { useSelector } from 'react-redux'
import { deleteAdById, getbidbyadid, updateAdByAdid } from '../../util/apis'

export default function SingleCampaingItem({item,  lastTimeOfBid,  adsLimitTime, fetchCurrentAds}) {
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const [show, setshow] = useState(false)
    const [bidlist, setbidlist] = useState(false)
    const fetchbidlist = () =>{
        getbidbyadid(adminUser?._id, adminUser?.accessToken, item?._id).then((res)=>{
            setbidlist(res.data)
        })
    }
    useEffect(() => {
        fetchbidlist()
    }, [])
    
    const approved = (id, maxvalue) =>{
        let data = {...item, hostedBy: id, maxBidLimit: maxvalue, adsStatus:'active'}
        updateAdByAdid(adminUser?._id, adminUser?.accessToken, item?._id, data).then((res)=>{
            fetchCurrentAds()
        })
    }

    const deleteItem = (adid) =>{
        deleteAdById(adminUser?.accessToken, adid).then((res)=>{
            if (res?.status === 200) {
                fetchCurrentAds()
            }else{
                console.log(res);
            }
        })
    }

    const handleEdit = (e) =>{
        e.preventDefault()
    }

  return (
    <>
        <div className='single-ad-row'>
            <div className='singlead-img'>
                <img src={adbanner} width="100%" />
            </div>
            <div className='singlead-info w-50'>
                <h3>{item.title}</h3>
                <p><span style={{width:'70px'}}>Ad Place</span>: {item.place}</p>
                <p><span style={{width:'70px'}}>Position</span>: {item.position}</p>
            </div>
            <div className='singlead-info w-50'>
                <h3>৳ {item.minBidLimit} <sub>(Min)</sub></h3>
                <p><span style={{width:'120px'}}>Last Date of Bid</span>: {lastTimeOfBid?.toLocaleDateString()}</p>
                <p><span style={{width:'120px'}}>Ad Expired In</span>: {adsLimitTime?.toLocaleDateString()}</p>
            </div>
            <FontAwesomeIcon icon={faCircleArrowDown} style={{fontSize:"25px", padding:'10px',color:'#faaa1c',cursor:'pointer'}} onClick={()=>setshow(!show)} />
            <FontAwesomeIcon icon={faTrashAlt} style={{fontSize:"25px", padding:'10px',color:'red',cursor:'pointer'}} onClick={()=>deleteItem(item?._id)} />
        </div>
        {show? (
            <div className='single-ad-row-bottom'>
                <h4>Applied</h4>
                <ul>
                    {bidlist?.map((e)=>{
                        let appliedDate = new Date(e.createdAt)
                        return(
                            <li>
                                <div className='uddokta-img'>
                                    <img src={uddoktathumbnail} width="100%" />
                                </div>
                                <h5>{e?.uddokta_id.vendorname}</h5>
                                <h5>৳{e?.amount}</h5>
                                <h5>{appliedDate.toLocaleDateString()}</h5>
                                <FontAwesomeIcon icon={faCheck} style={{padding:'10px', cursor:'pointer', color:'green',fontSize:'25px'}} onClick={()=>approved(e.uddokta_id?._id, e.amount)} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        ) : ""}
    </>
  )
}
