import React from 'react'
import Shops from '../components/Shop/Shops'

export function Shop(props) {
    

    return (
        <>
          <Shops />
        </>
    )
}
