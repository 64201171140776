import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree, faBoxes, faPhotoFilm } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Category() {
  const category = useSelector((state)=>state.category.categories)
  let subcategory = [...category];
  subcategory = subcategory.filter(category=>category.parentCategory);
  let graphics = [...category];
  graphics = graphics.filter(category=>category.type === 'graphics');
  return (
    <Container>
        <div className='dashboard-inner'>
                <h5 className='mt-3 mb-3'>Category</h5>
                <Row>
                    <Col lg={3}>
                        <Link to="/allcategory" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Category</p>
                                <h6>{category?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faTree} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/maincategory" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Main category</p>
                                <h6>{category?.length - subcategory?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faBoxes} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/subcategory" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Subcategory</p>
                                <h6>{subcategory?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faBoxes} />
                            </div>
                        </Link>
                    </Col>
                    <Col lg={3}>
                        <Link to="/graphicscategory" className='dashboard-box mt-3 d-flex justify-content-between align-items-center'>
                            <div className='box-left'>
                                <p>Total Graphics Category</p>
                                <h6>{graphics?.length}</h6>
                            </div>
                            <div className='box-right'>
                                <FontAwesomeIcon icon={faPhotoFilm} />
                            </div>
                        </Link>
                    </Col>
                </Row>
            </div>
    </Container>
  )
}
