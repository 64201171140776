import React,{useEffect, useState}from 'react'
import { Container, Row, Col, FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import slugify from 'react-slugify';
import { addOffer, getAllVoucher } from '../../util/apis';
import { updateNotificationSuccess } from '../../redux/notificationRedux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function AddOffer(props) {
    const [startDate, setStartDate] = useState(new Date());
    const dispatch = useDispatch()
    const [inputs, setinputs] = useState({})
    const [error, seterror] = useState("")
    const navigate = useNavigate()
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const category = useSelector((state) => state.category.categories)
    let notification = useSelector((state)=> state.notification.notification)
    let shop = useSelector((state)=> state.shop.shops)
    const [vouchers, setvouchers] = useState([])

    const handleChange = (e) =>{
        setinputs(prev=>{
            return{...prev, [e.target.name]:e.target.value};
        });
    };

    const fetchvouchers = () =>{
        getAllVoucher(adminUser?.accessToken).then((res)=>{
            setvouchers(res.data)
        })
    }

    useEffect(() => {
        fetchvouchers()
    }, [])

    const handleSubmit = (e) =>{
        e.preventDefault();
        let offer = {...inputs, voucherValidity:startDate, givento:'vendor'}
        addOffer(adminUser?.accessToken, offer).then((res)=>{
            if (res.data) {
                dispatch(updateNotificationSuccess({sms:"Offer Added Success",status:true}))
                navigate('/offerlist')
            } else {
                dispatch(updateNotificationSuccess({sms:"Offer Added Failed",status:false}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                console.log("error");
            }
        })
    }

    return (
        <>
        <Container>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            <div className='custom-form'>
                <div className='custom-card form-heading'>
                    <h4>Create Offer</h4>
                </div>
                <form className='custom-card' onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={9} md={8}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Select Voucher</label>
                                        <select className='form-control' name='voucherid' onChange={handleChange} required>
                                            <option value="">Select Voucher</option>
                                            {vouchers.filter(s => s.status === true).map((s)=>(
                                                <option value={s._id}>{s.vouchername}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Assigned To</label>
                                        <select className='form-control' name='assignedid' onChange={handleChange} required>
                                            <option value="">Select Uddokta</option>
                                            {shop.filter(s => s.status === 'active').map((s)=>(
                                                <option value={s._id}>{s.vendorname}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Max used times</label>
                                        <FormControl type='number' name='maximumusedtimes' onChange={handleChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6}>
                                    <FormGroup className='form-group'>
                                        <label className='mb-2'>Offer Validity</label>
                                        <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <p style={{color:"red"}}>{error}</p>
                        </Col>
                        <Col lg={3} md={4}>
                            <FormGroup className='form-group'>
                                <label className='mb-2'>Status</label>
                                <select className='form-control' name='status' onChange={handleChange} required>
                                    <option value="">Select Status</option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </select>
                            </FormGroup>
                            <Button variant='success' className='w-100' type='submit'>Submit</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        </Container>
        </>
    )
}
