import { faCheck, faAdd, faAngleLeft, faAngleRight, faFileArrowUp, faFileExcel, faPenToSquare, faSearch, faTrashCan,faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState,useEffect} from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Col, Container, FormControl, FormGroup, Row } from 'react-bootstrap'
import '../List/list.css'
import './product.css'
import { Link } from 'react-router-dom'
import { deleteProduct, deleteProductToStore, getAllProduct, getRequestedProducts, updateProduct, updateProductToStore } from '../../util/apis'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import BeatLoader from 'react-spinners/BeatLoader'
import { updateNotificationSuccess } from '../../redux/notificationRedux'

export function RequestedProducts(props) {
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch()
    const [itemPerPage, setitemPerPage] = useState(10)
    const [pageNumber, setPageNumber] = useState(0);
    const pageVisited = pageNumber * itemPerPage;
    const [list, setlist] = useState([])
    const pageCount = Math.ceil(list?.length / itemPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [searchedQuery, setsearchedQuery] = useState("")
    const adminUser = useSelector((state) => state.userInfo.currentUser)
    const [loader, setloader] = useState(false)
    let notification = useSelector((state)=> state.notification.notification)
    
    const fetchProducts = () =>{
        getRequestedProducts(adminUser?.accessToken).then((res)=>{
            setlist(res.data)
        })
    }

    useEffect(() => {
        setloader(true)
        setTimeout(()=>{
            dispatch(updateNotificationSuccess({sms:"",status:null}))
            setloader(false)
        }, 2000)
        fetchProducts()
    }, [dispatch])

    const [deleteItem, setdeleteItem] = useState(null);
    const handleRemoveItem = (item) =>{
        setModalShow(true)
        setdeleteItem(item?.productid._id)
    }

    const removeItem = () =>{
        console.log(deleteItem);
        
        deleteProduct(adminUser.accessToken, adminUser?._id, deleteItem).then((res)=>{
            if (res.data) {
                setModalShow(false)
                dispatch(updateNotificationSuccess({sms:"Product Delete Success",status:true}))
                setTimeout(() => {
                    dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                fetchProducts()
            } else {
                dispatch(updateNotificationSuccess({sms:"Product Delete Failed",status:false}))
                setTimeout(() => {
                  dispatch(updateNotificationSuccess({sms:"",status:null}))
                }, 2000);
                setModalShow(false)
            }
        })
    }

    const handleUpdateProduct = (item) =>{
        let updatedProduct = {...item.productid, status:true}
        updateProduct(dispatch, adminUser?.accessToken, updatedProduct, updatedProduct._id).then((res)=>{
            if (res?.status === 200) {
                let updatedItem = {...item, status:true}
                updateProductToStore(adminUser?.accessToken, updatedItem, adminUser?._id, item._id).then((res)=>{
                    fetchProducts()
                })
            } else {
                
            }
        })
    }


    const displayItems = list.filter(item=> {
        if (searchedQuery === "") {
          return item
        }
        else if(item.shopid?.vendorname.toLowerCase().includes(searchedQuery?.toLowerCase())) {
          return item
        }
      }).slice(pageVisited, pageVisited + itemPerPage)
        .map((item, index) => {
          return (
            <tr key={index}>
                <td>
                    <img src={`${process.env.REACT_APP_BASE_URL}/alluploads/${item.productid.photo}`} alt="" />
                </td>
                <td>{item.productid?.nameInBangla}</td>
                <td>{item.productid?.description}</td>
                <td>{item.shopid?.vendorname}</td>
                <td>{item.shopid?.businesstype}</td>
                <td>
                    <div className='action-btns'>
                        <a onClick={()=>handleRemoveItem(item)}><FontAwesomeIcon icon={faTrashCan} /></a>
                        <a onClick={()=>handleUpdateProduct(item)} to="/"><FontAwesomeIcon icon={faCheck} /></a>
                    </div>
                </td>
            </tr>
          );
        });

    return (
        <Container>
            <div className={notification.status === true? 'notification-bar active success' : notification.status === false? 'notification-bar active failed' : 'notification-bar'}>
                {notification.status === true? <FontAwesomeIcon icon={faCheck} /> : notification.status === false? <FontAwesomeIcon icon={faTimes} /> : ''}
                <p>{notification.sms}</p>
            </div>
            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='popup-modal'
                >
                <Modal.Body>
                    <FontAwesomeIcon icon={faCheck} />
                    <h4>Are you sure?</h4>
                    <Row>
                        <Col lg={6} md={6} sm={6}>
                            <Button variant='danger' onClick={()=>setModalShow(false)} className='w-100 mt-4'>Cancel</Button>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <Button variant='success' onClick={()=>removeItem()} className='w-100 mt-4'>Submit</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <div className='list'>
                <div className='table custom-card category-table'>
                    <h5 className='d-flex justify-content-between'>Requested Products </h5>
                    <div className='table-top'>
                        <div className='form-group'>
                            Show
                            <select onChange={(e)=>setitemPerPage(e.target.value )}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                            </select>
                            entries
                        </div>
                        <div className='form-group'>
                            <label>Search:</label>
                            <input type='text' onChange={(e)=>setsearchedQuery(e.target.value)} />
                        </div>
                    </div>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th style={{width:"100px"}}>Photo</th>
                                <th style={{width:"200px"}}>Product Name</th>
                                <th>Details</th>
                                <th style={{width:"200px"}}>Uddokta Name</th>
                                <th style={{width:"150px"}}>Uddokta Type</th>
                                <th style={{width:"50px"}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader? <BeatLoader color="#36d7b7" style={{textAlign:"center",display:"block"}} /> : displayItems}
                        </tbody>
                    </table>
                </div>
                <div className='list-bottom d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Showing {list?.length} items</p>
                    <ReactPaginate
                        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        activeClassName={"actvie-page"}
                    />
                </div>
            </div>
        </Container>
    )
}
